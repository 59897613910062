#dashboard-sidebar {
  position: absolute;

  /* margin-top: 12vh; */
  height: calc(100vh - 0vh);
  width: 12rem;
}

#dashboard-content {
  position: absolute;

  margin-left: 12rem;
  /* margin-top: 12vh; */
  width: calc(100vw - 12rem);
  height: calc(100vh - 0vh);
}
.dashboard-btn-div {
  display: none;   
}
table th {
  color: #111827;
}
.d-sm-none {
  display: none;
}
.mt-sm-2 {
  margin-top: 0rem;
}
.min-w-sm-25 {
  min-width: 25rem;
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  /* テーブル */
  table {
    table-layout: fixed;
  }
  table th,
  table td {
    padding: 1rem 15px!important;
    width: calc(60px + 40px + (15px*2));
  }
  table th a,
  table td a {
      width: 95%;
  }
}
@media screen and (max-width: 576px) {
  /* ヘッダー */
  .dashboard-btn-div.cp_hmenuac01 {
    right: 0!important;
  }
  /* サイドバー */
  #dashboard-sidebar {
    z-index: 1000;
  }
  body #dashboard-sidebar {
    left:-120vw;
    transition: all 0.5s ease-in-out;
  }
  body.open #dashboard-sidebar {
    left:0vw;
  }
  /* テーブル */
  table {
    table-layout: fixed;
  }
  table th,
  table td {
    padding: 1rem 15px!important;
    width: calc(60px + 40px + (15px*2));
  }
  table th a,
  table td a {
      width: 95%;
  }
  /* ログイン画面 */
  .login-form-wrap-top {
    padding: 6rem 0!important;
  }
  .login-form-wrap-top label,
  .login-form-wrap-top input {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .edit-wrap-top label,
  .edit-wrap-top input,
  .edit-wrap-top select{
    width: 90%;
  }
  /* 素材リスト */
  #dashboard-content {
    margin-left: 0;
    width: 100%;
    overflow-x: hidden;
  }
  .dashboard-wrap-top {
    padding:0 15px 2.5rem!important;
  }
  .dashboard-wrap-top .dashboard-border-span {
    min-width: 0!important;
  }
  .dashboard-wrap-top .dashboard-button {
    width: 100px!important;
  }
  .dashboard-btn-div {
    display: block;
  }
  .dashboard-btn-div.cp_hmenuac01 .cp_bar, 
  .dashboard-btn-div.cp_hmenuac01 .cp_bar:before, 
  .dashboard-btn-div.cp_hmenuac01 .cp_bar:after {
    background-color: #000;
  }
  .login-header-wrap {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }
  /* 確定ボタン */
  .btn-submit,
  .link-return {
    transform: translateX(-15px);
  }
  .d-sm-none {
    display: block;
  }
  .mt-sm-2 {
    margin-top: 0.5rem;
  }
  .min-w-sm-25 {
    min-width: 0;
    margin-bottom: 1.5rem;
  }
}