#ad-page-header {
  position: absolute;
  height: 8vh;
}

#ad-page-header > img {
  height: 8vh;
}

#ad-page-content {
  position: absolute;
  margin-top: 8vh;
  height: calc(100vh - 8vh);
  width: 100vw;
  overflow: scroll;
}

#ad-page-sidebar {
  position: fixed;
  z-index: 1;
  height: calc(100vh - 8vh);
}

#ad-page-list {
  position: absolute;
  z-index: 0;
}

@media screen and (max-width: 576px) {
  #ad-page-sidebar {
    background-color: rgb(248 250 252 / var(--tw-bg-opacity));
    width: 200px;
    box-shadow: 0px 8px 16px -2px rgba(10,10,10,0.1), 0px 0px 0px 1px rgba(10,10,10,0.02);
  }
  body.active-sidebar #ad-page-list{
    height: 100%;
  }
  body.active-sidebar #ad-page-sidebar {
    left: 0;
    transition: all 0.5s ease-in-out;
    padding-left: 15px;
    padding-right: 15px;
  }
  body:not(.active-sidebar) #ad-page-sidebar {
    left: -120vw;
    transition: all 0.5s ease-in-out;
  } 
}