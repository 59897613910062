@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}
.no-border-radius {
  border-radius: 0;
}

.no-border {
  border: none !important;
}

.blue {
  color: blue !important;
}

.mr-200px {
  margin-right: 200px !important;
}

.leaflet-control {
  margin: 0 !important;
}

.overflow-visible {
  overflow: visible !important;
}

.rw-calendar-popup {
  width: auto !important;
  padding: 0 !important;
  left: -0.5% !important;
  white-space: nowrap;
  font-size: smaller;
}

/* ===== Scrollbar CSS 1 ===== */
/* Firefox */
.custom-scrollbar-1 {
  scrollbar-width: auto;
  scrollbar-color: #404040 rgba(255, 255, 255, 0);
}

/* Chrome, Edge, and Safari */
.custom-scrollbar-1::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar-1::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

.custom-scrollbar-1::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(255, 255, 255, 1);
}

/* ===== Scrollbar CSS 1 ===== */

/* ===== Scrollbar CSS 2 ===== */
/* Firefox */
.custom-scrollbar-2 {
  scrollbar-width: auto;
  scrollbar-color: #404040 rgba(255, 255, 255, 0);
}

/* Chrome, Edge, and Safari */
.custom-scrollbar-2::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.custom-scrollbar-2::-webkit-scrollbar-track {
  background: rgba(212, 212, 212, 0.25);
}

.custom-scrollbar-2::-webkit-scrollbar-thumb {
  background-color: rgba(68, 68, 68, 0.5);
  border: 1px solid rgba(255, 255, 255, 1);
}

/* ===== Scrollbar CSS 2 ===== */

/* ===== Scrollbar CSS 3 ===== */
/* Firefox */
.custom-scrollbar-3 {
  scrollbar-width: auto;
  scrollbar-color: #404040 rgba(255, 255, 255, 0);
}

/* Chrome, Edge, and Safari */
.custom-scrollbar-3::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom-scrollbar-3::-webkit-scrollbar-track {
  background-color: rgba(126, 126, 126, 0.1);
  border-top: none;
  border-bottom: none;
}

.custom-scrollbar-3::-webkit-scrollbar-thumb {
  background-color: rgba(32, 32, 32, 0.5);
  min-height: 2rem;
}
/* ===== Scrollbar CSS 3 ===== */

/* 以下Sandpitで追加した箇所 */
.card-items {
  grid-template-columns: repeat(auto-fit, 16rem);
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .card-items {
    grid-template-columns: repeat(auto-fit, 15rem);
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .card-items {
    grid-template-columns: repeat(auto-fit, 48%);
  }
}
@media screen and (max-width: 576px) {
  /* ここにスタイルを記述します */
  .pl-\[8\.5rem\] {
      padding-left: 0;
  }
  .w-card {
    width:100%;
  }
  .w-card a img,
  .w-card a video {
    width:100%!important;
    object-fit: cover;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .select-area label{
    margin-right: 0.5rem;
  }
  .card-items {
    grid-template-columns: repeat(auto-fit, 100%);
  }
  .card-items > div{
    max-width:100%;
    padding: 0 15px;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  /* ナブ */
  .cp_hmenuac01 .cp_bar,
  .cp_hmenuac01 .cp_bar:before,
  .cp_hmenuac01 .cp_bar:after {
    display: block;
    width: 40px;
    height: 5px;
    content: '';
    -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
    border-radius: 5px;
    background: rgb(118 169 250 / var(--tw-border-opacity));
  }
  .cp_hmenuac01 .cp_bar {
    position: relative;
  }
  .cp_hmenuac01 .cp_bar:before,
  .cp_hmenuac01 .cp_bar:after {
    position: absolute;
  }
  .cp_hmenuac01 .cp_bar:before {
    top: 11px;
  }
  .cp_hmenuac01 .cp_bar:after {
    bottom: 11px;
  }
  .cp_hmenuac01 {
    position: absolute;
    width: 40px;
    padding: 1.5em 0;
    cursor: pointer;
    right: 15px;
    top: calc(50% - 53px/2);
  }
  .cp_hmenuac01.active .cp_bar {
    background: transparent;
  }
  .cp_hmenuac01.active .cp_bar:before {
    top: 0;
    transform: rotate(45deg);
  }
  .cp_hmenuac01.active .cp_bar:after {
    top: 0;
    transform: rotate(-45deg);
  }
  .xs-px-20 {
    padding: 0 15px;
  }
}